const loginButton = document.querySelector('#cbLoginbtn');

if (loginButton) {
    const formLogin = document.querySelector('form');
    const emailInput = formLogin.querySelector('input[name="email"]');
    const passwordInput = formLogin.querySelector('input[name="password"]');

    formLogin.addEventListener("submit", (e) => {
        e.preventDefault();
        return false;
    });

    loginButton.addEventListener('click', (e) => {
        e.preventDefault();

        if (emailInput.value == "mateus.bona@gmail.com" && passwordInput.value == "123") {
            localStorage.setItem('logged', true);
            return window.location.href = '/dashboard';
        }

        return false;
    })
}