export function settingsBtn() {
//     const settingsBtn = document.getElementById('settingsBtn');

//     if (settingsBtn) {
//       const chatbotSettings = document.getElementById("chatbotSettings");
//       settingsBtn.addEventListener('click', (e) => {
//         e.preventDefault();
//         chatbotSettings.classList.toggle("hidden");
//       });
//     }

//     const loginBtn = document.getElementById('loginBtn');
//     console.log(loginBtn);

//     if (loginBtn) {
//       console.log('botão');
//     }
}

