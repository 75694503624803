import './app/facebookSDK.js';
import './app/login.js';
import './app/settings.js';
import { toogleLoading } from './app/utils.js';

document.addEventListener(
  "DOMContentLoaded",
  (e) => {
    if (toogleLoading()) {

    }
  }
);