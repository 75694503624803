window.fbAsyncInit = function() {
    FB.init(
      {
        appId      : '1636214247209172',
        xfbml      : true,
        version    : 'v19.0'
      }
    );

    const fbLoginbtn = document.getElementById('fbLoginbtn');

    if (fbLoginbtn) {
      fbLoginbtn.addEventListener('click', () => {
        FB.login(
          (response) => {
            if (response.status === 'connected') {
              // Logged into your webpage and Facebook.
              alert('Logged into your webpage and Facebook.');
            } else {
              // The person is not logged into your webpage or we are unable to tell.
              console.log('User cancelled login or did not fully authorize.');
              window.location.href = '/';
            }
          },
          {
            scope: 'public_profile,email'
          }
        );
      });
    }

    if (window.location.pathname.includes('/dashboard')) {
      const fbWPPBAbtn = document.getElementById('fbWPPBAbtn');

      fbWPPBAbtn.addEventListener('click', (e) => {
        e.preventDefault();

        FB.login(
          (response) => {
            if (response.authResponse) {
              const accessToken = response.authResponse.accessToken;
              //Use this token to call the debug_token API and get the shared WABA's ID
            } else {
              console.log('User cancelled login or did not fully authorize.');
            }
          },
          {
            config_id: '920557059846283',
            response_type: 'code',
            override_default_response_type: true,
            extras: {
              setup: {}
            }
          }
        );
      });
    }
}